// Import styles
import '../scss/main.scss';

import Inputmask from 'inputmask';
import Glide from '@glidejs/glide';

document.addEventListener('DOMContentLoaded', function () {
    //  NAVIGATION

    //      Burger button
    const burger = document.querySelector('.burger');
    const navigation = document.querySelector('.navigation');

    if (burger)
        burger.onclick = () => {
            if (burger.classList.contains('burger--close')) {
                burger.classList.remove('burger--close');
                navigation.classList.remove('navigation--opened');
            } else {
                burger.classList.add('burger--close');
                navigation.classList.add('navigation--opened');
            }
        };

    //      Submenus positions
    const headerSubmenus = [...document.querySelectorAll('.submenu')].reverse();
    const setSubmenusPositions = () => {
        headerSubmenus.forEach((submenu) => {
            const submenuRect = submenu.getBoundingClientRect();
            const navigationRect = navigation.getBoundingClientRect();

            if (submenuRect.right >= navigationRect.right)
                submenu.classList.add('submenu--left');
        });
    };
    setSubmenusPositions();

    //  CONTENT
    //      Main height
    const setMainHeight = () => {
        const main = document.querySelector('.site-main');
        const header = document.querySelector('.header');
        const footer = document.querySelector('.footer');

        if (main && header && footer)
            main.style.minHeight = `calc(100vh - ${
                header.offsetHeight + footer.offsetHeight
            }px)`;
    };
    setMainHeight();

    //      BANNER
    if (document.querySelector('.banner__slider')) {
        let bannerCarousel = new Glide('.banner__slider', {
            type: 'carousel',
            autoplay: 3500,
            hoverpause: true,
        }).mount();
    }

    //      Q&A section
    const qnaList = document.querySelectorAll('.qna__item');
    qnaList.forEach((qnaItem) => {
        const btn = qnaItem.querySelector('.qna__btn');

        btn.onclick = () => {
            const isHasListOpenedItems = Boolean(
                [...qnaList].filter(
                    (qnaItem) =>
                        !qnaItem.classList.contains('qna__item--hidden')
                ).length
            );

            if (qnaItem.classList.contains('qna__item--hidden')) {
                qnaList.forEach((qnaItem) =>
                    qnaItem.classList.add('qna__item--hidden')
                );

                setTimeout(
                    () => qnaItem.classList.remove('qna__item--hidden'),
                    isHasListOpenedItems * 300
                );
            } else qnaItem.classList.add('qna__item--hidden');
        };
    });

    //  WINDOW RESIZING UPDATES
    window.onresize = () => {
        setMainHeight();
    };

    // GALLERY
    const galleries = document.querySelectorAll(
        '.content .wp-block-gallery:not(.academy-carousel)'
    );

    galleries.forEach((gallery, galIndex) => {
        const galleryPictures = gallery.querySelectorAll('.wp-block-image');

        const gallerySection = document.createElement('section');
        gallerySection.className = `academy-gallery academy-gallery--${galIndex}`;

        const galleryTrack = document.createElement('div');
        galleryTrack.setAttribute('data-glide-el', 'track');
        galleryTrack.classList.add('academy-gallery__track');

        const galleryList = document.createElement('ul');
        galleryList.classList.add('academy-gallery__list');

        const createNavigationButton = (classNames, text, action) => {
            const button = document.createElement('button');
            button.classList.add(...classNames);
            button.textContent = text;
            button.onclick = action;
            return button;
        };

        const prevButton = createNavigationButton(
            ['academy-gallery__button', 'academy-gallery__button--prev'],
            '<',
            () => galleryCarousel.go('<')
        );
        const nextButton = createNavigationButton(
            ['academy-gallery__button', 'academy-gallery__button--next'],
            '>',
            () => galleryCarousel.go('>')
        );
        const closeArea = createNavigationButton(
            ['academy-gallery__close-area'],
            'x',
            () => {
                gallerySection.classList.remove('academy-gallery--active');
                closeArea.classList.remove(
                    'academy-gallery__close-area--active'
                );
            }
        );
        const closeButton = createNavigationButton(
            ['academy-gallery__button', 'academy-gallery__button--close'],
            'x',
            () => {
                gallerySection.classList.remove('academy-gallery--active');
                closeArea.classList.remove(
                    'academy-gallery__close-area--active'
                );
            }
        );

        galleryPictures.forEach((pic, picIndex) => {
            const galleryItem = document.createElement('li');
            galleryItem.classList.add('academy-gallery__item');
            galleryItem.append(pic.cloneNode(true));
            galleryList.append(galleryItem);

            const btn = document.createElement('button');
            btn.className = 'academy-gallery__picture-button';
            btn.setAttribute('gallery-index', galIndex);
            btn.setAttribute('picture-index', picIndex);
            btn.append(...pic.children);
            pic.append(btn);

            btn.onclick = () => {
                gallerySection.classList.add('academy-gallery--active');
                closeArea.classList.add('academy-gallery__close-area--active');

                galleryCarousel.settings.animationDuration = 0;
                galleryCarousel.update();
                galleryCarousel.go(`=${picIndex}`);

                setTimeout(() => {
                    galleryCarousel.settings.animationDuration = 400;
                    galleryCarousel.update();
                }, 0);
            };
        });

        gallerySection.append(prevButton, nextButton, closeButton, closeArea);
        galleryTrack.append(galleryList);
        gallerySection.append(galleryTrack);
        gallery.closest('.content').append(gallerySection);

        const galleryCarousel = new Glide(`.academy-gallery--${galIndex}`, {
            type: 'carousel',
        }).mount();
    });

    //  Academy Carousel
    const carousels = document.querySelectorAll('.academy-carousel');
    if (carousels.length) {
        carousels.forEach((carousel) => {
            const carouselBefore = carousel.previousSibling;

            const carouselBlock = document.createElement('div');
            carouselBlock.classList = 'academy-carousel';

            const carouselTrack = document.createElement('div');
            carouselTrack.classList = 'academy-carousel__track';
            carouselTrack.setAttribute('data-glide-el', 'track');

            carousel.classList.remove('academy-carousel');
            carousel.classList.add('academy-carousel__list');

            carouselTrack.append(carousel);
            carouselBlock.append(carouselTrack);

            carouselBefore.after(carouselBlock);

            const carouselItems = carousel.querySelectorAll('.wp-block-image');

            if (carouselItems.length) {
                carouselItems.forEach((carouselItem) => {
                    const carouselItemPic = carouselItem.querySelector('img');
                    carouselItemPic.classList.add('academy-carousel__picture');

                    const carouselItemPicPlaceholder =
                        carouselItemPic.cloneNode(true);
                    carouselItemPicPlaceholder.classList.add(
                        'academy-carousel__picture--placeholder'
                    );
                    carouselItemPic.after(carouselItemPicPlaceholder);

                    carouselItem.classList.add('academy-carousel__item');
                });

                new Glide(carouselBlock, {
                    type: 'carousel',
                    autoplay: 3500,
                    hoverpause: true,
                }).mount();
            }
        });
    }
    const phoneInputs = document.querySelectorAll("input[type='tel']");
    phoneInputs.forEach((phoneInput) => {
        Inputmask('+38 (099) 999-99-99').mask(phoneInput);
    });

    //  YouTubePresentation
    const presentations = document.querySelectorAll('.presentation');
    presentations.forEach((presentation) => {
        const video = presentation.querySelector('.presentation__video');
        const videoURL = video.getAttribute('data-src');
        const button = presentation.querySelector('.presentation__watch');

        const youtubeIcon = button.querySelector(
            '.presentation__icon--youtube'
        );
        const spinnerIcon = button.querySelector(
            '.presentation__icon--spinner'
        );

        button.onclick = () => {
            youtubeIcon.remove();
            spinnerIcon.style.opacity = '1';

            video.src =
                videoURL + (videoURL.includes('?') ? '&' : '?') + 'autoplay=1';

            video.addEventListener('load', () => button.remove());

            button.onclick = null;
        };
    });
});
